<template>
  <div class="popup_wrap" style="width:550px; height: 500px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <h1 class="page_title">{{ $t('msg.ONEX050G010_addr.001') }}</h1>

    <div class="content_box">
      <table class="tbl_row">
        <colgroup>
          <col width="80px"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.ONEX050G010_addr.002') }}</th>
            <td>
              <div class="tbl_form">
                <span class="dv col_8">
                  <e-auto-complete-place
                    id="keyword"
                    @change="searchAgentAddr"
                    :ctr-cd="ctrCd"
                    :plc-cd="plcCd"
                    :is-char="true"
                    :is-char-alert="true"
                  />
                </span>
                <span class="dv">
                  <button type="button" class="tbl_icon search" @click="searchAgentAddr()">{{ $t('msg.NEWB010P020.006') }}</button>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="resultList.length === 0 && initFlag !== 'Y'">
          <tr>
            <td colspan="2">
              {{ $t('msg.ONEX050G010_addr.003') }}
            </td>
          </tr>
        </tbody>
        <tbody v-if="resultList.length > 0">
          <tr v-for="(vo, idx) in resultList" :key="'addr_' + idx">
            <td colspan="2" class="border_left_none">
              <span
                class="font_medium result_plc"
              >
                {{ vo.plcEnm }}
              </span>
              <p v-html="vo.sntcContHtml">
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import commons from '@/api/services/commons'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
export default {
  name: 'BLAgentAddressPop',
  components: {
    EAutoCompletePlace
  },
  props: {
    parentInfo: {
      type: Object,
      default () {
        return {
          addrCtrCd: '',
          addPlcCd: ''
        }
      }
    }
  },
  data () {
    return {
      resultList: [],
      initFlag: 'Y',
      keyword: '',
      ctrCd: '',
      plcCd: ''
    }
  },
  created () {
    this.ctrCd = this.parentInfo.addrCtrCd || ''
    this.plcCd = this.parentInfo.addrPlcCd || ''
    if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.addrPlcCd)) {
      this.plcCd = this.parentInfo.addrPlcCd
      const vo = {
        plcCd: this.parentInfo.addrPlcCd
      }

      this.searchAgentAddr(vo)
    }
  },
  methods: {
    searchAgentAddr (vo) {
      this.keyword = vo.plcCd
      if (this.$ekmtcCommon.isNotEmpty(this.keyword)) {
        commons.getAgentAddr(this.keyword).then(res => {
          this.initFlag = 'N'

          const result = res.data
          if (result !== undefined) {
            result.forEach(vo => {
              vo.sntcContHtml = vo.sntcCont.replace(/\n/g, '<br>')
            })
            this.resultList = result
          }
        })
      }
    },
    addrStrEnter (e) {
      this.searchAgentAddr()
    }
  }
}
</script>

<style scoped>
  .result_plc {color: #000;}
</style>
