var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "550px", height: "500px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("h1", { staticClass: "page_title" }, [
        _vm._v(_vm._s(_vm.$t("msg.ONEX050G010_addr.001"))),
      ]),
      _c("div", { staticClass: "content_box" }, [
        _c("table", { staticClass: "tbl_row" }, [
          _vm._m(0),
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G010_addr.002")))]),
              _c("td", [
                _c("div", { staticClass: "tbl_form" }, [
                  _c(
                    "span",
                    { staticClass: "dv col_8" },
                    [
                      _c("e-auto-complete-place", {
                        attrs: {
                          id: "keyword",
                          "ctr-cd": _vm.ctrCd,
                          "plc-cd": _vm.plcCd,
                          "is-char": true,
                          "is-char-alert": true,
                        },
                        on: { change: _vm.searchAgentAddr },
                      }),
                    ],
                    1
                  ),
                  _c("span", { staticClass: "dv" }, [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon search",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.searchAgentAddr()
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.NEWB010P020.006")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm.resultList.length === 0 && _vm.initFlag !== "Y"
            ? _c("tbody", [
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }, [
                    _vm._v(
                      " " + _vm._s(_vm.$t("msg.ONEX050G010_addr.003")) + " "
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm.resultList.length > 0
            ? _c(
                "tbody",
                _vm._l(_vm.resultList, function (vo, idx) {
                  return _c("tr", { key: "addr_" + idx }, [
                    _c(
                      "td",
                      {
                        staticClass: "border_left_none",
                        attrs: { colspan: "2" },
                      },
                      [
                        _c("span", { staticClass: "font_medium result_plc" }, [
                          _vm._v(" " + _vm._s(vo.plcEnm) + " "),
                        ]),
                        _c("p", {
                          domProps: { innerHTML: _vm._s(vo.sntcContHtml) },
                        }),
                      ]
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "80px" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }